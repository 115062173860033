.offer-container {
    margin-bottom: 4rem;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}
.offer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
    background-color: #fff6f6;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    position: relative;
    flex-wrap: wrap;
    gap: 15px;
}
.offer .image {
    height: 170px;
    border-radius: 5px;
    margin-bottom: 10px;
}
