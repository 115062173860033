.donations-card p {
    margin-bottom: 10px;
    display: flex;
    gap: 5px;
}
p {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
}
.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.support-page .button-container .button {
    margin: 0 1rem;
    display: flex;
    justify-content: center;
}
.button {
    border: none;
}
.button-cta {
    background-color: palevioletred;
}
.support-page .images-container {
    display: grid;
    grid-gap: 20px;
    margin: 3rem 0;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}
.support-page .images-container img {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    opacity: 0.3;
}
.book-section{
    display: flex;
    flex-direction: column;
    &>*{
        margin-top: 15px;
    }
}
img {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
}
img {
    border-style: none;
}
.donation{
    background-color: white;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 10px 0px;
    border-radius: 25px;
    div {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        span {
            font-weight: 600;
        }
        input{
            border-radius: 10px;
            border: none;
            padding: 15px;
            background-color: #f7f7f7;
        }
        & > * {
            padding: 5px;
        }
    }
}
