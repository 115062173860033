header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    height: var(--header-height);
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.07);
    padding: 1rem;
    z-index: 100;
    flex-wrap: wrap;
}

a {
    font-family: var(--font-headers);
    color: #eb7095;
    text-decoration: none;
}

a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

* {
    box-sizing: inherit;
}

user agent stylesheet a:-webkit-any-link {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
}

header .menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1 1;
    background-color: #ffffff;
    flex-wrap: wrap;
}

.menu-link {
    font-family: var(--font-headers);
    font-size: 17px;
    padding-bottom: 0.5rem;
    text-decoration: none;
    color: #eb7095;
    font-weight: bold;
    transition: all 0.5s ease;
    margin-right: 1.7rem;
}

.menu-link:hover {
    color: #db4b76;
}

.drop-menu {
    position: relative;
    display: flex;
    width: auto;
    margin-right: 1.7rem;
}

.drop-button {
    background-color: transparent;
    border: none;
    padding-left: 0;
    padding-right: 0;
    outline: none;
}

.drop-content {
    visibility: hidden;
    opacity: 0;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 2rem;
    min-width: 230px;
    background-color: #ffffff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04), 0 2px 8px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 10;
    transition: all 0.3s ease;
}

.top-line {
    width: 0;
    height: 3px;
    float: right;
    transition: all 0.5s ease;
    transition-delay: 0.3s;
    background-color: palevioletred;
}

header .menu .drop-menu .drop-content a {
    font-size: 16px;
    text-decoration: none;
    color: palevioletred;
    display: flex;
    width: 100%;
    padding: 8px 30px;
    transition: all 0.5s ease;
}

a {
    font-family: var(--font-headers);
    color: palevioletred;
    text-decoration: none;
}

a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

.event-card .line {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 1rem;
    max-height: 15px;
}

.dropdown {
    float: left;
    overflow: hidden;
}

.dropdown .dropbtn {
    border: none;
    outline: none;
    font-size: 16px;
    text-decoration: none;
    color: #eb7095;
    display: flex;
    width: 100%;
    font-weight: 700;
    padding: 0;
    transition: all 0.5s ease;
    background-color: white;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    float: none;
    color: #eb7095 !important;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

.dropdown-content a:hover {
    background-color: #ffffff;
}

.dropdown:hover .dropdown-content {
    display: block;
}
#menuToggle
{
  display: block;
  position: relative;
  top: 50px;
  left: 15px;
  
  z-index: 1;
  
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle a
{
  text-decoration: none;
  color: #232323;
  
  transition: color 0.3s ease;
}

#menuToggle a:hover
{
  color: tomato;
}


#menuToggle input
{
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  
  cursor: pointer;
  
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  
  -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#menuToggle span
{
  display: block;
  width: 40px;
  height: 4px;
  margin-bottom: 6px;
  position: relative;
  background: pink;
  border-radius: 3px;
  
  z-index: 1;
  
  transform-origin: 4px 0px;
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

#menuToggle span:first-child
{
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2)
{
  transform-origin: 0% 100%;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked ~ span
{
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked ~ span:nth-last-child(2)
{
  transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu
{
  position: absolute;
  width: 300px;
  margin: -100px 0 0 -50px;
  padding: 60px;
  padding-top: 125px;
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

#menu li
{
  padding: 10px 0;
  font-size: 22px;
  color: #eb7095;
}

/*
 * And let's slide it in from the left
 */
#menuToggle input:checked ~ ul
{
  transform: none;
}

@media (max-width:700px) {
    .dropdown .dropbtn {
        border: none;
        outline: none;
        font-size: 22px;
        text-decoration: none;
        display: flex;
        font-weight: 700;
        transition: all 0.5s ease;
        background-color: transparent;
    }
    .drop-menu {
        position: relative;
        display: flex;
        width: auto;
        margin-right: 1.7rem;
    }

    .drop-button {
        background-color: transparent;
        border: none;
        padding-left: 0;
        padding-right: 0;
        outline: none;
    }

}