.productivity-page .stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
    grid-gap: 5px;
}
.productivity-page .stats-grid .stat {
    display: flex;
    align-items: center;
}
.productivity-page .stats-grid .stat .stat-content span {
    color: #eb7095;
    font-weight: bold;
    font-family: var(--font-headers);
    font-size: 26px;
}