.content-body {
    width: 90%;
    max-width: 820px;
    margin: 0 auto;
    padding-top: 150px;
    padding-bottom: 150px;
    height: 100%;
}
.section-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.section-title hr {
    height: 5px;
    width: 48px;
    margin-bottom: 40px;
    background-color: rgb(247, 159, 188);
}
hr {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: calc(1.45rem - 1px);
    background: hsla(0, 0%, 0%, 0.2);
    border: none;
    height: 1px;
}
hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}
.row {
    display: flex;
    flex-direction: row;
}
.content{
    display: flex;
    flex-direction: column;
}
p {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
    font-size: 18px;
}
* {
    box-sizing: inherit;
}
user agent stylesheet
p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}
.button {
    border: none;
    
}
.button-cta {
    background-color: palevioletred;
}
.button {
    padding: 1rem;
    font-family: var(--font-headers);
    text-decoration: none;
    color: #ffffff;
    font-weight: bold;
    border-radius: 40px;
    min-width: 220px;
    text-align: center;
    transition: all 0.3s ease;    
}
@media (max-width:900px){
    .row {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .intro {
        justify-content: center;
        flex-wrap: wrap;
    }
  }