.accordion {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}
.accordion__panel {
    padding: 20px;
    -webkit-animation: fadein 0.35s ease-in;
    animation: fadein 0.35s ease-in;
}
[hidden] {
    display: none;
}
[hidden], template {
    display: none;
}
* {
    box-sizing: inherit;
}
user agent stylesheet
div {
    display: block;
}
style attribute {
    visibility: visible;
}
.accordion__button {
    background-color: #f4f4f4;
    color: #444;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
    padding-bottom: 15px;
    padding-top: 15px;
}
.accordion__button:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
*:before {
    box-sizing: inherit;
}
.accordion__button[aria-expanded='true']::before, .accordion__button[aria-selected='true']::before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.accordion__panel {
    padding: 20px;
    -webkit-animation: fadein 0.35s ease-in;
    animation: fadein 0.35s ease-in;
}
p {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
}
form {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
}
.form-styling {
    width: 100%;
    height: 35px;
    padding-left: 15px;
    border: thin;
    border-radius: 15px;
    margin-bottom: 20px;
    background: whitesmoke;
}
button, input {
    overflow: visible;
}
button, input, optgroup, select, textarea {
    font: inherit;
    margin: 0;
}