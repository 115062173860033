.compartim-page {
    min-height: 70vh;
}
.content-body {
    width: 90%;
    max-width: 820px;
    margin: 0 auto;
    padding-top: 150px;
    padding-bottom: 150px;
}
.section-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.event-card {
    background-color: #fff6f6;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    margin-bottom: 6rem;
    position: relative;
}
.event-card .circle-up {
    width: 90px;
    height: 90px;
    position: absolute;
    top: -45px;
    left: -45px;
    background-color: #f5b4c8;
    border-radius: 50%;
}
.event-card .circle-bot {
    width: 60px;
    height: 60px;
    position: absolute;
    background-color: #f5b4c8;
    right: -30px;
    bottom: -30px;
    border-radius: 50%;
    z-index: -1;
}
video {
    object-fit: contain;
    overflow-clip-margin: content-box;
    overflow: clip;
}
