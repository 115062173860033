ul li {
    color: rgb(245, 123, 164);
}
ul li {
    padding-left: 0;
}
li {
    margin-bottom: calc(1.45rem / 2);
    display: flex;
}
li {
    display: list-item;
    text-align: -webkit-match-parent;
}
ul {
    list-style: none;
    font-family: var(--font-headers);
    font-weight: bold;
    margin: 3em 0;
}
ul {
    margin-left: 1.45rem;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
    list-style-position: outside;
    list-style-image: none;
}
ul {
    list-style-type: disc;
}
li *:last-child {
    margin-bottom: 0;
}
b {
    font-weight: bold;
}
.event-card h3 {
    text-align: center;
}
h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-headers);
}
h3 {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
    color: inherit;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: bold;
    text-rendering: optimizeLegibility;
    font-size: 1.38316rem;
    line-height: 1.1;
}