.overlay-background{
    background-color: rgba(0, 0, 0, 0.219);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 200;
    bottom: 0;
    
}
.overlay-background > video{
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 10px 10px 10px #000000;
    max-width: 1200px;
}