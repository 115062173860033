.shipment-data{
    display: flex;
    flex-direction: column;
    & > * {
        margin: 10px;
        padding: 10px;
        width: 100%;
        border-radius: 15px;
    }
}
.line-1 {
    width: 100%;
    height: 15px;
    border-bottom: 1px solid #bebebe;
    text-align: center;
}
.line-2 {
    font-size: 20px;
    background-color: #ffffff;
    padding: 0 5px;
}
.donations-card-paypal{
    background-color: rgb(235, 93, 140);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 35px;
    width: 100%;
}
.button-paypal {
    position: relative;
    z-index: 1;
}

@media (max-width:900px){
    .button{
        min-width: 5px;
    }
    .circle-up{
        display: none;
    }
    .circle-bot{
        display: none;
    }
  }