.book {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.cover {
    width: 50%;
    height: 100%;
    object-fit: cover;
}

.text-box {
    background-color: pink;
    width: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
.first-page {
    background: url(../../assets/images/libro.png) no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 800px;
    position: relative;   
}

.first-page>div {
    position: absolute;
    color: white;
    font-size: 100px;
    font-weight: 700;
    top: 250px;
    width: calc(100% - 5%);
    display: flex;
}

.first-page>div>p {
    color: white;
    font-size: 90px;
    font-weight: 700;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-left: 45px;
}

.first-page>div>div {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: right;
    align-items: center;
    gap: 25px;
}

.button-green {
    background-color: #9ebd9e;
    border-radius: 45px;
    padding: 10px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.button-green>p {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
}

.button-green>img {
    margin: 0;
}

.button-green:hover {
    background-color: #0c0c0c;
}

.second-page {
    background-color: rgb(108, 43, 121);
    width: 100%;
    height: 730px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

.second-page>p {
    font-weight: 300;
    display: flex;
    text-align: center;
    font-size: 25px;
}

.third-page {
    background-color: #f7f7f7;
    width: 100%;
    height: 803px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.third-page>p {
    font-size: 55px;
    padding: 35px;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 55px;
}

.row>div {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.paragraph:hover {
    background-color: #7b62df31;
}

.row>div>h3 {
    text-align: center;
    font-size: 18px;
}

.row>div>p {
    text-align: justify;
    font-size: 18px;
}

.pink-button {
    background-color: #ef7d8a;
    max-width: 150px;
    display: flex;
    justify-content: center;
    font-weight: 400;
    padding: 15px 55px;
    color: white;
}

.pink-button:hover {
    background-color: #7b62df;
}

.five-page {
    background-image: linear-gradient(#4024bf, #736bd3, #c8c9e0);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 805px;
    padding-top: 55px;
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.four-page {
    background-image: linear-gradient(to right, #4325bd, #cf8cad);
}

.four-page>h1 {
    color: #a9e5c4;
    padding: 15px;
    font-size: 35px;
    font-weight: 400;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
}

.card-img {
    max-width: 800px;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 25px;
    color: white;
}

.green-btn {
    border-style: solid;
    border-radius: 25px;
    padding: 10px 25px;
    max-width: 150px;
    display: flex;
    justify-content: center;
    color: #a9e5c4;
    font-weight: 500;
}

.green-btn:hover {
    background-color: rgba(255, 255, 255, 0.377);
}

.card-img>img {
    border-radius: 55px;
}

.card-gray {
    -webkit-filter: grayscale(100%) !important;
    filter: grayscale(100%) !important;
}

.card-gray:hover {
    -webkit-filter: grayscale(0%) !important;
    filter: grayscale(0%) !important;
}

.six-page {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 150px;
    padding: 25px 0;
    position: relative;
    height: 250px;
    z-index: 1;
}

.logo-anim {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    opacity: 0.5;
}

@media (max-width:900px) {
    .book {
        flex-direction: column;
    }
    .cover {
        width: 100%;
    }
    .first-page>div>p {
        font-size: 55px;
    }

    .first-page>div {
        flex-wrap: wrap;
    }

    .first-page>div>div {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .second-page {
        height: auto;
    }

    .row {
        flex-wrap: wrap;
    }

    .third-page {
        height: auto;
    }

    .five-page {
        height: auto;
        flex-wrap: wrap;
    }

    .six-page {
        font-size: 70px;
    }
    .first-page {
           background-color: #000000;
           background: palevioletred;
           height: 50px;
           position: none;
    }
    .card-img > img{
        width: 100%;
    }
    .column > img{
        width: 100%;
    }
}