.investigation-page .doctors-grid {
    margin-top: 60px;
    margin-bottom: 60px;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}
.doctor {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin-bottom: 1em;
    padding: 16px;
    background-color: #f5f5f5;
}
.doctor .image-box {
    width: 160px;
    height: 160px;
    margin: 1.5rem auto;
    overflow: hidden;
    border-radius: 200px;
}
ul li {
    color: rgb(27, 27, 27);
}