.footer {
    width: 100%;
    color: #424242;
    background-color: #e6e6e6;
    -webkit-columns: 2;
    columns: 2;
    padding-top: 6vh;
    padding-bottom: 50px;
}
.content-screen {
    width: 90%;
    max-width: 1280px;
    margin: 0 auto;
}
.footer .footer-content .main-content {
    display: flex;
    flex-direction: row;
    width: 100%;
}
element.style {
    flex: 1 1 0%;
    justify-content: space-between;
}
.footer .footer-content .footer-links {
    font-size: 14px;
    display: flex;
    height: 100%;
}
.footer .footer-content .footer-links .column {
    padding: 0 0.5em;
}

.column {
    display: flex;
    flex-direction: column;
}
.social-links {    
    display: flex;
    justify-content: flex-end;
}

.row {
    display: flex;
    flex-direction: row;
}
.white {
    color: white;
}
.icon {
    display: flex;
}
.footer hr {
    bottom: 20%;
    top: 90%;
    width: 100%;
    display: flex;
    border: none;
    height: 1px;
    margin: 1em 0;
}

hr {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: calc(1.45rem - 1px);
    background: hsla(0, 0%, 0%, 0.2);
    border: none;
    height: 1px;
}
hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}
.footer .footer-bottom {
    height: 35px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.row {
    display: flex;
    flex-direction: row;
}
a {
    font-family: var(--font-headers);
    color: palevioletred;
    text-decoration: none;
}

a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}
.footer .footer-bottom p {
    margin: 0;
}

p {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
    font-size: 15px;
}