p {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
}

ol {
    margin: 2em 0;
    color: #eb7095;
    font-weight: bold;
    list-style: none;
    counter-reset: li;
    font-weight: 700;
}
ol {
    margin-left: 1.45rem;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
    
}
.doctor-page .doctor-profile img {
    width: 240px;
    height: auto;
    margin-right: 1.5rem;
}

img {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
}
img {
    border-style: none;
}