.aifs {
    display: flex;
    justify-content: center;
    align-items: center;
}
.column {
    display: flex;
    flex-direction: column;
}
li *:last-child {
    margin-bottom: 0;
}
li > p {
    margin-bottom: calc(1.45rem / 2);
}
p {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
}
ul {
    list-style: none;
    font-family: var(--font-headers);
    font-weight: bold;
    margin: 3em 0;
}