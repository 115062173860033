.main-header hr {
    background-color: rgb(247, 159, 188);
    height: 5px;
    width: 48px;
    margin-bottom: 8px;
}
hr {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: calc(1.45rem - 1px);
    background: hsla(0, 0%, 0%, 0.2);
    border: none;
    height: 1px;
}
hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}
